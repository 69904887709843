<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">
                {{ $t("WEEKLY_REPORTS.EDIT_WEEKLY_REPORT") }}
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <weekly-report-form
              :loading="loading"
              :weeklyReportData="weeklyReport"
              :formErrors="formErrors"
              @weeklyReportSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import WeeklyReportForm from "./partials/WeeklyReportForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultWeeklyReport from "./defaultWeeklyReport";

export default {
  layout: "DashboardLayout",

  components: { WeeklyReportForm },

  mixins: [alertLeave],

  data() {
    return {
      weeklyReport: cloneDeep(defaultWeeklyReport),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    console.log(this.$route.params.id);
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("weeklyReports/get", id);
        this.weeklyReport = this.$store.getters["weeklyReports/weeklyReport"];
        console.log(this.weeklyReport);
        this.loading = false;
        if (this.weeklyReport.cafeteria) {
          if (!this.weeklyReport.cafeteria.id) {
            delete this.weeklyReport.cafeteria;
          }
        }
        if (this.weeklyReport.serviceCenter) {
          if (!this.weeklyReport.serviceCenter.id) {
            delete this.weeklyReport.serviceCenter;
          }
        }
        if (this.weeklyReport.establishment) {
          if (!this.weeklyReport.establishment.id) {
            delete this.weeklyReport.establishment;
          }
        }
        if (this.weeklyReport.cafeteria) {
          if (!this.weeklyReport.cafeteria.id) {
            delete this.weeklyReport.cafeteria;
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List WeeklyReports" });
    },

    viewWeeklyReport() {
      this.alertLeave = false;
      this.$router.push({
        name: "View WeeklyReport",
        params: { id: this.weeklyReport.id },
      });
    },

    async handleSubmit(weeklyReport) {
      this.loading = true;
      const weeklyReportData = cloneDeep(weeklyReport);
      if (weeklyReportData.serviceCenter) {
        if (!weeklyReportData.serviceCenter.id) {
          delete weeklyReportData.serviceCenter;
        }
      }
      if (weeklyReportData.establishment) {
        if (!weeklyReportData.establishment.id) {
          delete weeklyReportData.establishment;
        }
      }
      if (weeklyReportData.cafeteria) {
        if (!weeklyReportData.cafeteria.id) {
          delete weeklyReportData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch("weeklyReports/update", weeklyReportData);
        this.$notify({
          type: "success",
          message: this.$t("WEEKLY_REPORTS.WEEKLY_REPORT_UPDATED"),
        });
        this.viewWeeklyReport();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
